import React from "react";

export const ExternalIcon = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 13 13"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.58333 2.70833C7.43967 2.70833 7.3019 2.65127 7.20032 2.54968C7.09874 2.4481 7.04167 2.31033 7.04167 2.16667C7.04167 2.02301 7.09874 1.88523 7.20032 1.78365C7.3019 1.68207 7.43967 1.625 7.58333 1.625H10.8333C10.977 1.625 11.1148 1.68207 11.2164 1.78365C11.3179 1.88523 11.375 2.02301 11.375 2.16667V5.41667C11.375 5.56033 11.3179 5.6981 11.2164 5.79968C11.1148 5.90127 10.977 5.95833 10.8333 5.95833C10.6897 5.95833 10.5519 5.90127 10.4503 5.79968C10.3487 5.6981 10.2917 5.56033 10.2917 5.41667V3.47425L5.25796 8.50796C5.1558 8.60663 5.01897 8.66122 4.87695 8.65999C4.73493 8.65876 4.59907 8.60179 4.49864 8.50136C4.39821 8.40093 4.34124 8.26507 4.34001 8.12305C4.33878 7.98103 4.39337 7.8442 4.49204 7.74204L9.52575 2.70833H7.58333ZM1.625 3.79167C1.625 3.50435 1.73914 3.2288 1.9423 3.02563C2.14547 2.82247 2.42102 2.70833 2.70833 2.70833H5.41667C5.56033 2.70833 5.6981 2.7654 5.79968 2.86698C5.90127 2.96857 5.95833 3.10634 5.95833 3.25C5.95833 3.39366 5.90127 3.53143 5.79968 3.63302C5.6981 3.7346 5.56033 3.79167 5.41667 3.79167H2.70833V10.2917H9.20833V7.58333C9.20833 7.43967 9.2654 7.3019 9.36698 7.20032C9.46857 7.09874 9.60634 7.04167 9.75 7.04167C9.89366 7.04167 10.0314 7.09874 10.133 7.20032C10.2346 7.3019 10.2917 7.43967 10.2917 7.58333V10.2917C10.2917 10.579 10.1775 10.8545 9.97437 11.0577C9.7712 11.2609 9.49565 11.375 9.20833 11.375H2.70833C2.42102 11.375 2.14547 11.2609 1.9423 11.0577C1.73914 10.8545 1.625 10.579 1.625 10.2917V3.79167Z"
      fill="white"
    />
  </svg>
);

export const GithubIcon = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 23 23"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M11.491 1.71387C5.93643 1.71162 1.4375 6.2083 1.4375 11.7584C1.4375 16.1473 4.25186 19.878 8.17129 21.2481C8.69912 21.3807 8.61826 21.0056 8.61826 20.7495V19.0088C5.57031 19.3659 5.44678 17.3489 5.24238 17.012C4.8291 16.3067 3.85205 16.1271 4.14404 15.7901C4.83809 15.433 5.54561 15.88 6.36543 17.0906C6.9584 17.9688 8.11514 17.8206 8.70137 17.6746C8.82939 17.1468 9.10342 16.6751 9.48076 16.309C6.32275 15.743 5.00654 13.8158 5.00654 11.5248C5.00654 10.413 5.37266 9.39102 6.09141 8.5667C5.6332 7.20781 6.13408 6.04434 6.20147 5.87139C7.50645 5.75459 8.86309 6.80576 8.96865 6.88887C9.70986 6.68897 10.5566 6.5834 11.5045 6.5834C12.4568 6.5834 13.3059 6.69346 14.0538 6.89561C14.3076 6.70244 15.5654 5.79951 16.7783 5.90957C16.8435 6.08252 17.3331 7.21904 16.9019 8.55996C17.6296 9.38652 18.0002 10.4175 18.0002 11.5315C18.0002 13.8271 16.675 15.7564 13.508 16.3135C13.7793 16.5802 13.9947 16.8984 14.1416 17.2493C14.2885 17.6003 14.364 17.977 14.3638 18.3574V20.8843C14.3817 21.0864 14.3638 21.2863 14.7007 21.2863C18.6785 19.9454 21.5423 16.1877 21.5423 11.7606C21.5423 6.2083 17.0411 1.71387 11.491 1.71387Z" />
  </svg>
);

export const EmailIcon = () => (
  <svg
    width="28"
    height="28"
    xmlns="http://www.w3.org/2000/svg"
    aria-hidden="true"
    preserveAspectRatio="xMidYMid meet"
    viewBox="0 0 24 24"
  >
    <path d="M20 4H4c-1.103 0-2 .897-2 2v12c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2zm0 2v.511l-8 6.223l-8-6.222V6h16zM4 18V9.044l7.386 5.745a.994.994 0 0 0 1.228 0L20 9.044L20.002 18H4z" />
  </svg>
);

export const LinkedinIcon = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 -4 15 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3.63307 14.9995V4.87904H0.20333V14.9995H3.63307ZM1.91865 3.49643C3.11466 3.49643 3.85912 2.7193 3.85912 1.74814C3.83683 0.755077 3.1147 -0.000488281 1.94134 -0.000488281C0.768163 -0.000488281 0.000976562 0.755092 0.000976562 1.74814C0.000976562 2.71934 0.745253 3.49643 1.89626 3.49643H1.91854H1.91865ZM5.53143 14.9995H8.96117V9.34775C8.96117 9.04528 8.98345 8.74312 9.07403 8.52689C9.32197 7.92256 9.8863 7.29664 10.8337 7.29664C12.0748 7.29664 12.5713 8.22472 12.5713 9.58522V14.9995H16.0009V9.19646C16.0009 6.08785 14.3088 4.64144 12.0522 4.64144C10.202 4.64144 9.38966 5.65577 8.93835 6.34663H8.96125V4.87883H5.5315C5.57651 5.82848 5.5315 14.9993 5.5315 14.9993L5.53143 14.9995Z" />
  </svg>
);
